window.$ = require('jquery');
window.jQuery = window.$;

var Swiper = require('swiper');
require('./swiper.js');
require('./list.js');

$(document).ready(function() {

 	// Works with old version of swiper
	$('.swiper-container-event').each(function(index, element){
		var $this = $(this);
		$this.addClass('instance-' + index);
		var swiper = new Swiper('.instance-' + index, {
			slidesPerView: 3,
			spaceBetween: 30,
			direction: 'horizontal',
			loop: true,
			prevButton: '.swiper-button-prev',
			nextButton: '.swiper-button-next',
			pagination: '.swiper-pagination',
			paginationType: 'bullets',
			paginationClickable: true,
			breakpoints: {
				1050: {
					slidesPerView: 2,
				},
				500: {
					slidesPerView: 1,
					spaceBetween: 10,
				}
			}
		});
	});


	// Lunch menus
	if ( document.getElementById('matistan') ) {

		var loadingCity = false;
		var currentCity = '';
		var lunchCities = [
			{
				name: 'sundsvall',
				lat: 62.390812,
				lng: 17.306927
			},
			{
				name: 'harnosand',
				lat: 62.632320,
				lng: 17.937950
			}
		];

		var options = {
		  enableHighAccuracy: true,
		  timeout: 5000,
		  maximumAge: 0
		};

		function success(pos) {
			var crd = pos.coords,
				userLat = crd.latitude,
				userLng = crd.longitude,
				shortest = {},
				shortestDistance = 0;

			for (var i = 0; i<lunchCities.length; i++) {
				var city = lunchCities[i];
				var distance = getDistance(userLat, userLng, city.lat, city.lng);
				lunchCities[i]['distance'] = distance;
				if (distance < shortestDistance 
					|| shortestDistance === 0) {
					shortestDistance = lunchCities[i].distance;
					shortest = lunchCities[i];
				}
			}
			currentCity = shortest.name;
			
			init();

			return shortest;
		}

		function error(err) {
			console.warn(`ERROR(${err.code}): ${err.message}`);
			init();
		}

		navigator.geolocation.getCurrentPosition(success, error, options);

		function getDistance(lat1,lon1,lat2,lon2) {
			var R = 6371; // Radius of the earth in km
			var dLat = deg2rad(lat2-lat1);  // deg2rad below
			var dLon = deg2rad(lon2-lon1); 
			var a = 
				Math.sin(dLat/2) * Math.sin(dLat/2) +
				Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
				Math.sin(dLon/2) * Math.sin(dLon/2)
			; 
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
			var d = R * c; // Distance in km
			return d;
		}

		function deg2rad(deg) {
			return deg * (Math.PI/180)
		}

		// City dropdown
		var ddOpen = false;
		$('.js-dropdown').click(function(){
			
			if ( ddOpen == false ) {
				$(this).addClass('open');
				ddOpen = true;
			} else {
				$(this).removeClass('open');
				ddOpen = false;
			}

		});

		$('.js-toggle-takeaway').click( function(){
			$(this).toggleClass('is-active');
			$('.js-lunch-type').toggleClass('show-takeaway');
		});

		// Change city
		$('.js-city').click(function(){

			currentCity = $(this).attr('data-city');
	
			var takeaway = $(this).closest('.js-dropdown').data('takeaway');
			console.log( takeaway );
	
	
			$('.js-dropdown span').html($(this).text());
			
	    	if (!loadingCity) {
	    		loadingCity = true;
		    	changeUrl(takeaway);
	    	}

	    	setActiveDay($('.lunch').data('current-day'));
			

			var cityMenu = $('.lunch#' + currentCity);
			$('.lunch').fadeOut("fast", function(){
				
		    	cityMenu.fadeIn();
		    });

			setBgImg();

		});

		// Change day
		$('.js-lunch-nav').click(function(){

			var dayIndex = $(this).find('a').data('day');
			setActiveDay(dayIndex);

		});

		function init() {

			setCity();

			var currentCityName = currentCity;
			if ( currentCity == 'harnosand' ) {
				currentCityName = 'Härnösand';
			}

			$('.js-dropdown span').html(currentCityName);

			setActiveDay($('.lunch').data('current-day'));
			
			var cityMenu = $('.lunch#' + currentCity);
			cityMenu.fadeIn();

			setBgImg();

		}

		function setCity(){
			
			var urlArray = window.location.pathname;
			var cityFromUrl = urlArray.split('/');		

			if ( cityFromUrl[2] ) {
				currentCity = cityFromUrl[2];
				// console.log('from url: ' + currentCity);
			} else if ( currentCity ) {
				// console.log('already set: ' + currentCity);
			} else  {
				currentCity = 'sundsvall';
				// console.log('default: ' + currentCity);
			}

		}

		function setActiveDay(index){

			// Activate navigation
			$('.js-lunch-nav').removeClass('is-active');
			$('.js-lunch-nav').eq(index).addClass('is-active');

			// // Activate day
			$('.day').removeClass('is-active');
			$('#' + currentCity + ' .day').eq(index).addClass('is-active');

		}
	
		function changeUrl(takeaway) {
			

			if( takeaway ){
				takeaway = '?takeaway=1';
			} else {
				takeaway = '?takeaway=0';	
			}

			window.history.replaceState({}, document.title, "/" + "lunchguiden/" + currentCity + takeaway);
			loadingCity = false;

		}

		function setBgImg(){
			var img = $('.get-bg-img.' + currentCity).attr('data-bgimg');
			$('#bg-img').css('background-image', 'url(' + img + ')');
		}
	}


	// Detect screen width
	var screenWidth = $(window).width();
	window.addEventListener('resize', function () {
		screenWidth = $(window).width();
	});


	// Store fetched popup data for reuse
	var popupData = [];
	var loadingEvent = false;
	window.popupData = popupData;

	// Event listeners for popup
	$(document).on('click', '.popup', function(e) {
		if( e.target == $(this)[0] ) {
			history.back();
		}
	});

	$(document).on('click', '.popup .close', function(e) {
		history.back();
	});

	$(window).on('popstate', hidePopup);

    $(document).on('click', '.open-event', function(e){
    	if ( screenWidth < 800 ) {
    		// Open as single page
    	} else {
	    	e.preventDefault();
	    	if (!loadingEvent) {
	    		loadingEvent = true;
		    	var url = $(this).attr('href');
		    	loadPopup(url, false);
	    	}
	    	return false;
    	}
    });

	function hidePopup(e) {
		$('body').css('overflow', 'auto');
		if (e.originalEvent.state === null) {
			$('.popup').fadeOut();
		} else {
			loadPopup(e.originalEvent.state, true);
		}
	}

	function showPopup(data, url, replace) {
		// Populate popup inner
		$('.popup .inner').html(data).parent().fadeIn();
		$('body').css('overflow', 'hidden');
		// History API push url
		if (replace || document.location.href === url) {
			history.replaceState(url, document.title, document.location.href);
		} else {
			if (history.pushState) {
				history.pushState(url, null, url);
			}
		}
		loadingEvent = false;
	}

	function loadPopup(url, replace) {
		for (var i = 0; i < popupData.length; i++) {
			if (popupData[i].url == url) {
				showPopup(popupData[i].data, url, replace);
				return;
			}
		}
    	$.ajax({
    		url: url,
    		success: function(data) {
    			popupData.push({url: url, data:data});
    			showPopup(data, url);
    		}
    	});
	}

	$(document).ajaxStart(function(){
	    $("#wait").css("display", "block");
	});

	$(document).ajaxComplete(function(){
	    $("#wait").css("display", "none");
	});


	// Change input placeholder and button on resize
    var $window = $(window);

    function checkWidth() {
        var windowsize = $window.width();
        if (windowsize < 500) {
			$('.search-holder input').attr('placeholder','Sök');
        } else {
			$('.search-holder input').attr('placeholder','Sök på evenemang, artist, datum, månad, plats...');
        }
    }
    checkWidth();
    $(window).resize(checkWidth);


	// Filter with list.js
	var options = {
	    valueNames: [ 'js-event', 'js-date', 'js-original-date', 'js-location' ]
	};

	var eventList = new List('filter-events', options);


});